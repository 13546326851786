import React from "react";
import useLanguage from "../../Hooks/useLanguage";

const StoragePage = ({ content, points }) => {
  const langToggle = useLanguage;
  return (
    <section className="storage-section">
      <div className="container-fluid">
        <h3 className="h3">
          {langToggle(
            content.content_ua,
            content.content_ru,
            content.content_en
          )}
        </h3>
        <div className="storage-wrapper">
          <div className="row">
            {points.map((item, index) => {
              return (
                <div key={index} className="col-xl-6 col-lg-6 col-md-6 col-12">
                  <p className="storage-wrapper_item">
                    {langToggle(
                      item.content_ua,
                      item.content_ru,
                      item.content_en
                    )}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default StoragePage;
