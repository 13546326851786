import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import StorageMainScreen from "../components/Pages/StoragePage/MainScreen";
import StoragePage from "../components/Pages/StoragePage/StoragePage";
import useLanguage from "../components/Hooks/useLanguage";

const Storage = ({ data }) => {
  const langToggle = useLanguage;
  const seo = data.strapiStorage.seo;
  const homeTitle = data.strapiStorage.title;
  const content = data.strapiStorage.content;
  const bg = data.strapiStorage.bg_img;
  const points = data.strapiStorage.content_title;
  return (
    <Layout>
      <SEO
        title={langToggle(
          homeTitle.title_ua,
          homeTitle.title_ru,
          homeTitle.title_en
        )}
        description={langToggle(
          seo.seo_description_ua,
          seo.seo_description_ru,
          seo.seo_description_en
        )}
        keywords={[
          langToggle(
            seo.seo_keywords_ua,
            seo.seo_keywords_ru,
            seo.seo_keywords_en
          ),
        ]}
      />

      <StorageMainScreen homeTitle={homeTitle} bg={bg} />
      <StoragePage content={content} points={points} />
    </Layout>
  );
};

export default Storage;

export const query = graphql`
  query StorageQuery {
    strapiStorage {
      seo {
        seo_description_ua
        seo_description_ru
        seo_description_en
        seo_keywords_ua
        seo_keywords_ru
        seo_keywords_en
      }
      title {
        title_ua
        title_ru
        title_en
        sub_title_ua
        sub_title_ru
        sub_title_en
      }
      bg_img {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      content {
        content_ua
        content_ru
        content_en
      }
      content_title {
        content_ua
        content_ru
        content_en
      }
    }
  }
`;
